import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LeadersJson from "../../HomePage/Assets/Data/Leaders.json";
import "../Css/LeaderProfile.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { MdEmail } from "react-icons/md";
import { FaLinkedin } from "react-icons/fa6";

//* Leader Profile Page
//* ---------------------
const LeaderProfile = () => {
  const { routingName } = useParams();

  useEffect(() => {
    Aos.init({
      disable: window.innerWidth < 700,
    });
  }, []);

  const navigate = useNavigate();
  const handleProfile = (index) => {
    // navigate("/about/" + name);
    navigate("/about/" + LeadersJson[index].routingName);
  };

  //* Find the leader's data based on the routingName
  //* -------------------------------------------------
  const leader = LeadersJson.find(
    (Profile) => Profile.routingName === routingName
  );

  if (!leader) {
    return <div>Leader not found</div>;
  }

  //* Leader Profile Page UI
  //* ------------------------
  return (
    <div className="AboutLeader">
      <div className="LeaderComponent">
        <div className="ProfileCard">
          <div data-aos="fade-down" className="P_Card">
            <div className="P_Image">
              <img src={leader.image} className="Image" alt="leader_Image" />
            </div>
            <div className="P_Desc">
              <div className="desc">
                <h2 className="name">{leader.name}</h2>
                <h4 className="desg">{leader.designation}</h4>
                <h5 className="P_loc">{leader.Loc}</h5>
              </div>
              <div className="active_i"></div>
            </div>
          </div>
          <div data-aos="fade-up" className="switchComp">
            <div className="switch_P">
              {LeadersJson.map((data, index) => (
                <img
                  className={
                    data.id === leader.id ? "activeImage" : "switchImage"
                  }
                  key={index}
                  src={data.image}
                  alt=""
                  onClick={() => handleProfile(index)}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="DescriptCard">
          <div data-aos="fade-down" className="Desc_Glass1 desc_glass">
            <span className="P_summary">{leader.summary}</span>
            <span className="P_skill_summary">{leader.skills}</span>
          </div>
          <div className="split">
            <div data-aos="fade-up" className="split_Glass1 desc_glass">
              <span className="certify_head">Certifications</span>
              <div className="cert_desc">
                {leader.certifications.map((cert, index) => (
                  <span className="cert_desc_text" key={index}>
                    {cert}
                  </span>
                ))}
              </div>
            </div>
            {/* <div data-aos="fade-up" className="split_Glass2 desc_glass">
              <a className="li_nk1 li_nk" href={`${leader.link}`}>
                <FaLinkedin className=" ic_on ic_on1" />
                <span className="te_xt">LinkedIn</span>
              </a>
              <a className="li_nk2 li_nk" href={`mailto:${leader.email}`}>
                <MdEmail className=" ic_on ic_on2" />
                <span className="te_xt">Email</span>
              </a>
            </div> */}
          </div>
        </div>
      </div>
      <div className="about-bg1"></div>
    </div>
  );
};

export default LeaderProfile;
