import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import { motion } from "framer-motion";
import profileData from "../Assets/Data/Profiles.json";

import { IoIosArrowForward } from "react-icons/io";
import { FaLinkedin } from "react-icons/fa6";

import "../Css/Teams.css";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

const Teams = () => {
  const [page, setPage] = useState(null);
  const swiperRef = useRef(null);
  const colorArray = ["#DBFF00"];

  return (
    <div className="myProfileSwiper">
      <div className="profile-container">
        {/* Left Arrow */}
        <div
          className="dark-left-container"
          onClick={() => swiperRef.current.swiper.slidePrev()}
        >
          <IoIosArrowForward color="white" size={32} />
        </div>

        <Swiper
          grabCursor={true}
          centeredSlides={true}
          centeredSlidesBounds={true}
          slidesPerView={"auto"}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: true,
          }}
          spaceBetween={10}
          speed={800}
          modules={[Pagination, Autoplay]}
          ref={swiperRef}
          touchMoveStopPropagation={false}
          className="swiper-scrollbar-horizontal"
          breakpoints={{
            // when window width is >= 640px
            640: {
              spaceBetween: 20, // Space for medium screens
            },
            // when window width is >= 768px
            768: {
              spaceBetween: 30, // Space for large screens
              // speed: 1000
            },
          }}
          onSlideChange={(swiper) => setPage(swiper.realIndex + 1)}
        >
          {profileData.map((data) => {
            return (
              <SwiperSlide className="main swiper-slide" key={data.id}>
                {({ isActive }) => (
                  <div>
                    <div className={`profile-section`}>
                      <img
                        src={data.image}
                        alt="person"
                        className="personImg"
                        loading="lazy"
                      />
                      <div className={`bg`}></div>
                      <div className="profile">
                        <p
                          className="personName"
                          style={{
                            color: "#fff",
                          }}
                        >
                          {data.name}
                        </p>
                        <div
                          className="personDesignation"
                          style={{
                            color: colorArray[data.id % colorArray.length],
                          }}
                        >
                          {data.designation}
                        </div>
                        {/* <a
                          className="link-body-emphasis li-logo"
                          rel="noreferrer"
                          href={data.link}
                          target="_blank"
                        >
                          <FaLinkedin size={35} className="bi" />
                        </a> */}
                      </div>
                    </div>
                    {isActive && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 1.5 }}
                        className="description-section"
                      >
                        <p className="description">{data.desc}</p>
                      </motion.div>
                    )}
                  </div>
                )}
              </SwiperSlide>
            );
          })}
        </Swiper>

        {/* Right Arrow */}
        <div
          className="dark-right-container"
          onClick={() => swiperRef.current.swiper.slideNext()}
        >
          <IoIosArrowForward color="white" size={32} />
        </div>
      </div>
      <div className="profilepaging">
        <div className="profile-page">
          {page} / {profileData.length}
        </div>
      </div>
    </div>
  );
};

export default Teams;
